/* #loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
}

.loader-content {
  width: 200px;
  margin: 150px auto;
}

#fader {
  opacity: 0;
  transition: opacity 1s ease;
} */

html, body, .homepage, .frontpage, .App, #root {
  min-height: 100%;
  height: 100%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('/img/background_pattern.jpg') repeat #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'tecnicogrueso';
  src: url('/fonts/tecnico_bold-webfont.woff2') format('woff2'),
       url('/fonts/tecnico_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, .timeline p, span {
  color: #d1784a;
}

p, .page .col ul li {
  color: #5a4d9b;
}

h1 {
  text-align: center;
  font-family: 'tecnicogrueso';
  font-size: 3rem;
}

h1 span {
  color: #5a4d9b;
}

h2 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 	1.5625rem;
  margin-bottom: 0;
  padding-bottom: 0;
}

h3 {
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  padding: 0;
  margin: 10px;
  margin-bottom: 10px;
}

p, .page .col ul li {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.container {
  margin: 0 auto;
  padding: 0px;
  width: 100%;
}

.row, .col, .s12, .row .col .s12, .row .col  {
  padding: 0;
}

.row {
  margin: 0px;
  padding: 0px 40px;
}

/* ------------------------------------------------------------------- */

/* Navigation styling  */

nav {
  background-color: #5a4d9b;
  box-shadow: none;
}


.sidenav .row {
  position: relative;
  line-height: 0;
  font-size: 1.5rem;
  text-align: center;
  padding: 0px;
}

.sidenav .row hr {
  width: 30%;
  margin-top: 2px;
}

.sidenav .row .left-line {
  float: left;
}

.sidenav .row .right-line {
  float: right;
}

.nav {
  margin-bottom: 20px;
}

.nav li {
    color: #d1784a;
    font-size: 1.375rem;
    font-weight: 400;
    /* height: 0px; */
    line-height: 32px;
    padding-top: 5px;
    padding-bottom: 8px;
    display: block;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    border-radius: 200px;
    background-color: white;
    text-align: center;
    margin-top: 30px;
}

.nav-logo {
  width: 40%;
  display: block;
  margin: 20px auto 40px auto;
}

/* .exitsign {
  position: absolute;
  bottom: 40px;
  right: 50px;
  line-height: 0;
} */

.exitsign {
  position: fixed;
  bottom: 30px;
  right: 30px;
  margin-right: 0px!important;
}

.exitsign img{
  width: 50px;
  height: 50px;
}

.sidenav {
  width: 100%;
  background-color: #5a4d9b;
  padding: 10px 50px;
  height: 100%;
}

.nav a {
  padding: 0;
}

.sidenav-trigger {
  position: fixed;
  bottom: 30px;
  right: 40px;
  z-index: 999;
  height: 60px;
  width: 60px;
  cursor: pointer;
}

.sidenav-trigger img {
  width: 60px;
}

.connect-list ul li {
  text-align: left;
  margin-top: 10px;
  font-size: 	1.25rem;
  font-weight: 300;
}


.connect-list ul li img {
  width: 40px;
  height: 40px;
  float: left;
  margin-top: 4px;
  margin-right: 15px;
}

.home-button {
  width: 80px;
  height: 52px;
  position: fixed;
  bottom: 30px;
  left: 0px;
  background-size: 38px 36px;
  background-position: 22px 8px;
  background-image: url('/img/home_icon.png');
  background-repeat: no-repeat;
  z-index: 10;
  background-color: #5a4d9b;
  -webkit-border-top-right-radius: 100px;
  -webkit-border-bottom-right-radius: 100px;
  -moz-border-radius-topright: 100px;
  -moz-border-radius-bottomright: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

/* ------------------------------------------------------------------- */

/* Top navigation  */
.top-bar {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.25);
  z-index: 999999;
  font-family: 'Nunito', sans-serif;
  border-top: #d1784a 4px solid;
}

.top-bar ul {
  padding: 0 0 0 50px;
  margin: 0;
}

.top-bar ul li {
  display: inline-block;
  padding: 12px 40px 12px 40px;
  cursor: pointer;
  transition: background-color 0.35s ease;
  color: #5a4d9b;
}

.top-bar ul li:hover, .top-bar ul .active li {
  background-color: #d1784a;
  color: white;
}


/* ------------------------------------------------------------------- */

/* Homepage styling  */
.homepage {
  padding: 0!important;
  margin: 0;
  width: 100%;
  max-width: 100%;
}
.frontpage {
  background: url('/img/background_pattern_frontpage.jpg') repeat #ffffff;
  padding: 50px 40px 30px 40px;
  height: 100%;
}

.web-logo {
display: block;
margin: 0 auto;
height: 40%;
cursor: pointer;
}

.divider {
  background-color: #d1784a;
  width: 100%;
}

.payoff {
  text-align: center;
  padding: 0!important;
}
.payoff span {
  font-family: 'Bilbo Swash Caps', cursive;
  font-size: 2.3rem;
}

.down-button {
  position: absolute;
  bottom: 30px;
  margin: 0;
  padding: 0!important;
  left: calc(50% - 30px);
}

.down-button img {
  width: 60px;
  margin: 0 auto;
  padding: 0;
  display: block;
  cursor: pointer;
}

.header {
  width: 100%;
  height: 230px;
  background: url('/img/trainphoto.png') no-repeat;
  background-size: cover;
  background-position: center top;
  border-top: #5a4d9b 4px solid;
  border-bottom: #5a4d9b 4px solid;
}

.bio {
  margin-bottom: 40px;
}

.bio .col p {
  margin-top: 10px;
}

.timeline {
  margin-bottom: 25px;
}

.timeline-heading {
  margin-bottom: 0;
}
.timeline img {
  width: 60%;
  display: block;
  margin: 0 auto;
}

.timeline p {
  border-left: #5a4d9b 1px solid;
  padding-left: 10px;
  margin-left: 10px;
  font-weight: 200;
}

.timeline .timeline-arrow {
  width: 40px;
  height: 40px;
  margin-left: -10px;
  margin-top: -10px;
  background-size: 100%;
  background-image: url('/img/arrow_down.png');
  background-repeat: no-repeat;
}

.footer {
  background-image: url('/img/hippocar_highres.png');
  height: 300px;
  background-size: cover;
  background-position: right top;
  position: relative;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: inset 0px 21px 6px -15px rgba(0,0,0,0.55),
  inset 0px -15px 6px -15px rgba(0,0,0,0.75);

  -moz-box-shadow: inset 0px 21px 6px -15px rgba(0,0,0,0.55),
  inset 0px -15px 6px -15px rgba(0,0,0,0.75);

  box-shadow: inset 0px 21px 6px -15px rgba(0,0,0,0.55),
  inset 0px -15px 6px -15px rgba(0,0,0,0.75);
}

.footer-nav {
  padding: 0!important;
  margin: 0;
  position: absolute;
  bottom: 15px;
  left: 0;
}
.footer .col {
  background-color: #5a4d9b;
  text-align: center;
  padding: 8px 40px 10px 40px;
  margin-top: 10px;
  -webkit-border-top-right-radius: 100px;
  -webkit-border-bottom-right-radius: 100px;
  -moz-border-radius-topright: 100px;
  -moz-border-radius-bottomright: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.footer span {
  margin-top: 30px;
  font-size: 1.5rem;
  color: white;
}

.footer-nav .sidenav-trigger {
  position: relative;
  bottom: inherit;
  z-index: 1;
  height: inherit;
  width: inherit;
}

/* ------------------------------------------------------------------- */

/* Project overview styling  */
.projects-header {
  height: 250px;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: #5a4d9b 7px solid;
  margin-top: 50px;
}

.projects-header img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.react-header-title {
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
}

.react-header-title h2 {
  text-align: center;
  margin-top: 15px;
  color: #5a4d9b;
}

.page .col ul {
  padding-left: 20px!important;
}

.page .col ul li {
  list-style-type: circle!important;
}

.card {
  margin: 0!important;
  min-height: 200px;
}

.card-img {
  width: 200px;
  height: 150px;
  margin-right: 20px;
  float: left;
  overflow: hidden;
}

.card .card-content {
  padding: 25px;
  height: 200px;
}

.card-img img {
  object-fit: cover;
  height: inherit;
  width: inherit;
  transition: width 0.2s, height 0.2s, opacity 0.2s, margin-top 0.2s;
}

.card-img img:hover {
  opacity: 0.7;
  margin-top: -40px;
  width: 250px;
  height: 210px;
}

.card-title h3 {
  text-align: left;
  font-weight: 300;
  margin-top: 0px;
}

.card-content p a:hover, .card-title:hover {
  text-decoration: underline!important;
}

.card-summary-smallscreen {
  display: none;
}

.card-summary-midscreen {
  display: none;
}

.card-summary-bigscreen {
  display: none;
}

.card-summary-fullscreen {
  display: inherit;
}

.project-details {
  margin-top: 20px;
}

.project-list {
  margin-bottom: 100px;
}

/* ------------------------------------------------------------------- */

/* Single Project overview styling  */
.project-controls .col .btn, .project-controls .col .btn-large, .btn-small {
  background-color: #5a4d9b!important;
  -webkit-border-bottom-right-radius: 15px;
  -webkit-border-bottom-left-radius: 15px;
  -moz-border-radius-bottomright: 15px;
  -moz-border-radius-bottomleft: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-top: -3px;
  text-transform: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 	1.125rem;
  display: inherit;

}

.project-controls .col .btn, .project-controls .col .btn-large, .btn-small a {
  padding: 0px;
}


.project-controls .col .btn:active {
  background-color: #ce7d54!important;
}

.project-controls .col .btn:hover {
  background-color: #d1784a!important;
}

.btn, .btn-large, .btn-small {
  background-color: #d1784a!important;
}


.project-controls .col {
  padding: 0 .75rem;
}

.materialboxed {
  width: 100%;
  height: 100px;
  object-fit: fill;
}

.material-placeholder .active {
  object-fit: contain;
}

.gallery .col {
  margin-bottom: 10px;
}
.gallery .col:nth-child(odd) {
  padding-right: 10px;
}

.gallery .col:nth-child(even) {
  padding-left: 10px;
}

.collapsible {
  margin: 30px auto!important;
}

.collapsible-header span {
  font-weight: 600;
  font-size: 	1.125rem;
}

.collapsible-header {
  position: relative;
}

.collapsible-body {
  background-color: rgba(255, 255, 255, 0.30);
}

.content {
  margin-top: 10px;
}

.content .col {
  margin-bottom: 10px;
}

.collapsible-header .material-icons {
  margin-top: 3px;
}

/* ------------------------------------------------------------------- */
/* Contact  */

.contact-container .page .col ul {
  padding-left: 0!important;
  margin-top: 30px;
}

.contact-container h1 {
  text-align: left;
}

.contact-container .projects-header {
  height: 150px;
  border: none;
}

.contact-container .projects-header img {
  object-position: bottom;
}

.contact-container .connect-list ul li {
  list-style: none!important;
  position: relative;
}

.contact-container .connect-list ul li span {
  position: absolute;
  top: 20px;
}

.contact-container .connect-list ul li img {
  float: none;
  width: 60px;
  height: 60px;
}

/* ------------------------------------------------------------------- */
/* Media Queries  */

@media screen and (min-width: 1280px) {
  .container {
    max-width: 100%;
  }

  .header {
    height: 450px;
  }

  .row {
    padding: 0 25%;
  }

  .frontpage {
    padding: 100px 25% 0 25%;
  }

  .timeline  {
    margin-bottom: 10px;
  }

  .timeline img {
    width: 280px
  }
  
  h3 {
    margin: 40px 0 0px 0;
    font-size: 2rem;
  }
  
  .timeline-content {
    margin-bottom: 100px;
  }

  .timeline-content .col h2 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 2.5rem;
  }

  .down-button {
    bottom: 30px;
  }

  .down-area {
    width: 100%;
    height: 30px;
  }

  .footer {
    height: 500px;
    background-position: right -160px;

  }

  .footer .col {
    -webkit-border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    border-radius: 100px;
    width: 120%!important;
  }

  .footer-nav {
    top: 80px;
    padding: 0 25%!important;
  }

  .sidenav {
    width: 35%;
  }

  .exit-sign {
    height: 50px;
    bottom: 92px;
  }

  .exitsign img {
    cursor: pointer;
  }

  .card-img {
    height: 170px;
  }

  .card .card-content {
    height: 220px;
  }

  .projects-header {
    padding: 0!important;
    height: 500px;
  }

  .materialboxed {
    width: 100%;
    height: 270px;
  }

  .react-header-title {
    height: 80px;
    margin-top: 22px;
  }

  .react-header-title h2 {
    font-size: 2.5rem!important;
    margin-top: 20px;
  }

  .home-button {
    width: 100px;
    height: 72px;
    background-size: 48px 46px;
    background-position: 26px 12px;
  }

  .sidenav-trigger img {
    width: 70px;
  }

  .sidenav-trigger {
    bottom: 40px;
    display: none;
  }

  .top-bar {
    display: visible;
  }

  .home-button-link {
    display: none;
  }

  .down-button img {
    width: 70px;
  }

  .down-button {
    bottom: 30px;
  }

  .down-area {
    width: 100%;
    height: 30px;
  }
  
}

@media screen and (max-width: 1600px) {
  .row {
    padding: 0 10%;
  }

  .frontpage {
    padding: 50px 40px 0px 40px;
  }

  .projects-header {
    padding: 0;
  }

  .materialboxed {
    width: 100%;
    height: 400px;
  }

  .footer-nav {
    padding: 0 10%!important;
  }

  .footer {
    background-position: right -100px;

  }

  .timeline-content {
    padding: 0 25%;
  }
}

/* @media screen and (max-width: 1450px) {
  .exitsign {
    margin-top: 0px;
  }
} */
@media screen and (max-width: 1280px) {

  .projects-header {
    margin-top: 0;
  }
  .footer {
    background-image: url('/img/hippocar_lowres.png');
    background-position: right top;
  }
  
  .card-summary-bigscreen {
    display: inherit;
  }
  
  .card-summary-fullscreen {
    display: none;
  }

  .materialboxed {
    width: 100%;
    height: 300px;
  }

  .footer-nav {
    padding: 0!important;
  }

.timeline {
  padding: 0 18%;
}

.timeline-content {
  padding: 0 10%;
}

.down-area {
  width: 100%;
  height: 30px;
}

.exitsign {
  position: relative;
  bottom: inherit;
  right: inherit;
  float: right!important;
}

.sidenav-trigger {
  display: visible;
}

.top-bar {
  display: none;
}

.home-button-link {
  display: visible;
}

}


@media screen and (max-width: 1000px) {
  .materialboxed {
    width: 100%;
    height: 200px;
  }

}

@media screen and (max-width: 900px) {
  
  .materialboxed {
    width: 100%;
    height: 300px!important;
  }
}

@media screen and (max-width: 800px) {
  
  .card-summary-midscreen {
    display: inherit;
  }
  
  .card-summary-bigscreen {
    display: none;
  }

  .card-title h3 {
    font-size: 1.25rem;
  }

  .card {
    margin: .5rem 40px 1rem 40px;
    min-height: 122px;
  }
  
  .card-img {
    width: 180px;
    height: 182px;
    margin-right: 20px;
    float: left;
    overflow: hidden;
  }

  .card .card-content {
    padding: 10px;
  }

  #materialbox-overlay {
    display: none;
  }
  
  .material-placeholder .active {
    position: relative!important;
    z-index: 1!important;
    height: auto!important;
    width: 100%!important;
    left: 0!important;
    top: 0!important;
    opacity: 1!important;
  }

  .materialboxed {
    width: 100%;
    height: auto!important;
  }

  .materialboxed:hover:not(.active) {
    opacity: 1;
  }

  .timeline {
    padding: 0 40px;
  }

  .row .gallery .s6 {
    width: 100%;
    padding: 0;
  }

  /* .exitsign {
    margin-top: 0px;
  } */
  
}

@media screen and (max-width: 600px) {
  
  .card-summary-smallscreen {
    display: inherit;
  }

  .card-summary-midscreen {
    display: none;
  }

  .card-title h3 {
    font-size: 1.25rem;
  }

  .card {
    margin: .5rem 40px 1rem 40px;
    min-height: 122px;
  }
  
  .card-img {
    width: 100px;
    height: 102px;
    margin-right: 20px;
    float: left;
    overflow: hidden;
  }

  .card .card-content {
    padding: 10px;
    height: 100px;
  }

  .project-controls .col .btn, .project-controls .col .btn-large, .btn-small {
    font-size: 	1rem;
  }

  .project-controls {
    /* padding: 0; */
  }


}

@media screen and (max-width: 500px) {
  .project-controls .col {
    padding: 0 3px;
  }

  .project-controls .col {
    width: 35%!important;
  }

  .project-controls .two {
    width: 29%!important;
  }
}

@media screen and (max-width: 400px) {
  .header {
    height: 200px;
  }

  .nav-logo {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .sidenav {
    padding: 10px 40px;
  }

  .nav li {
    margin-top: 25px;
  }

  .connect-list {
    margin-top: 30px;
  }
  .connect-list ul li {
    margin-top: 10px;
  }

  /* .exitsign {
    right: 40px;
  } */

  .card-title h3 {
    font-size: 	1.125rem;
    margin-bottom: 0px;
  }

  .card-img {
    margin-right: 10px;
  }

  .project-controls .col .btn, .project-controls .col .btn-large, .btn-small a {
    padding: 1px 5px;
  }

  /* .exitsign {
    margin-top: -20px;
  } */

}

@media screen and (max-width: 350px) {
 .row {
   padding: 0 30px;
 }

.row .footer-nav {
  padding: 0;
}

.projects-header {
  padding: 0;
  height: 200px;
}

.projects-header img {
  margin-top: 0;
}

 .frontpage {
  padding: 30px 30px 00px 30px;
 }

 .sidenav .row {
  font-size: 	1.4375rem;
  }

  .sidenav .row hr {
  width: 26%;
  }

 .sidenav-trigger {
   right: 30px;
   bottom: 20px;
   height: 50px;
   width: 50px;
  }

 .sidenav-trigger img, .down-button img {
   width: 50px;
   height: 50px;
   cursor: pointer;
  }

 h1 {
  font-size: 2rem;
  margin-bottom: 15px;
  }

 .payoff span {
   font-size: 2rem;
  }

  .nav li {
    margin-top: 20px;
    line-height: 0;
    padding-top: 17px;
    padding-bottom: 20px;
    font-size: 1.25rem;
  }

  .connect-list ul li {
    font-size: 	1.125rem;
  }

  .connect-list ul li img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .connect-list {
    margin-top: 25px;
  }

  .sidenav {
    padding: 10px 30px;
  }

  .nav a {
    padding: 0!important;
  }

  .connect-list ul li {
    margin-top: 0px;
  }

  /* .exitsign {
    right: 30px;
    bottom: 30px;
    margin-top: -30px;
  } */

  .exitsign img {
    width: 45px;
    height: 45px;
  }

  .home-button {
    width: 66px;
    height: 44px;
    bottom: 20px;
    background-size: 32px 30px;
    background-position: 18px 7px;
  }

  .card-title h3 {
    text-align: left;
    margin: 0!important;
  }
  
  .card-img {
    display: none;
  }

  .card, .card .card-content {
    min-height: auto;
    height: auto;
  }

  .project-controls .col .btn, .project-controls .col .btn-large, .btn-small a {
    padding: 0 2px;
  }

  .project-controls .col {
    width: 33.3333333333%!important;
  }

  .project-controls .two {
    width: 33.3333333333%!important;
  }

  .collapsible-header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .collapsible-header .material-icons {
    margin-top: 3px;
  }

  .down-button {
    bottom: 20px;
  }

  .down-area {
    width: 100%;
    height: 20px;
  }

}